import axios from "axios"
import { AddAlert } from "../../components"
import { cicloIntervalo, idiomaString, paramsApi } from "../../helpers"
import api from "../../helpers/api"
import apiV3 from "../../helpers/apiV3"
import apiAuth from "../../helpers/apiAuth"
import apiOld from "../../helpers/apiOld"
import { history } from "../../helpers/history"
import listasJson from "./listas.json"
import apiIp from "../../helpers/apiIp"

import apiBase from "../../helpers/apiBase"
import { listarGruposEstabelecimentos, listarGruposProdutos, listarMeusProjetos, listarMeusProjetosEmpresas, listarMeusProjetosEstabelecimentos, listarMeusProjetosProdutos } from "../../modules/meusProjetos/redux/meusProjetosActions"
import { setProjetosFiltro } from "../../modules/projetos/redux/projetosActions"
const nls = require('../nls/pt-BR.json')


export const loading = e => ({
  type: 'LOADING',
  payload: true
})

export const loaded = e => ({
  type: 'LOADING',
  payload: false
})

export const getSistema = payload => ({
  type: 'GET_SISTEMA',
  payload
})

export const setSidebarLeft = e => ({
  type: 'SIDEBAR_LEFT',
  payload: e
})

export const setSidebarRigth = e => ({
  type: 'SIDEBAR_RIGHT',
  payload: e
})

export const modalOpen = payload => ({
  type: 'MODAL_OPEN',
  payload
})

export const modalClose = e => ({
  type: 'MODAL_OPEN',
  payload: ''
})

export const modalAlertaInfo = e => ({
  type: 'MODAL_ALERTA_INFO',
  payload: e
})

export const modalAlertaClean = e => ({
  type: 'MODAL_ALERTA_CLEAN',
  payload: ''
})

export const setTitlePage = e => ({
  type: 'TITLE_PAGE',
  payload: e
})

export const validacaoCampos = e => ({
  type: 'VALIDACAO',
  payload: e
})

export const setCarregarUsuarioLogado = e => ({
  type: 'USUARIO_LOGADO_CARREGAR',
  payload: e
})

export const setListarProjetos = e => ({
  type: 'LIST_PROJETOS',
  payload: e
})

export const setInfoProjeto = e => ({
  type: 'INFO_PROJETO',
  payload: e
})

export const setInfoCliente = e => ({
  type: 'INFO_CLIENTE',
  payload: e
})

export const setListarGrupos = e => ({
  type: 'LIST_GRUPOS',
  payload: e
})

export const setInfoGrupo = e => ({
  type: 'INFO_GRUPO',
  payload: e
})

export const setListarEmpresas = e => ({
  type: 'LIST_EMPRESAS',
  payload: e
})

export const setInfoEmpresa = e => ({
  type: 'INFO_EMPRESA',
  payload: e
})

export const setListarCategorias = e => ({
  type: 'LIST_CATEGORIAS',
  payload: e
})

export const setInfoCategoria = e => ({
  type: 'INFO_CATEGORIA',
  payload: e
})

export const setListarCiclos = payload => ({
  type: 'LIST_CICLOS',
  payload
})

export const setInfoCiclo = payload => ({
  type: 'INFO_CICLO',
  payload
})



export const setFilters = payload => ({
  type: 'INFO_FILTERS',
  payload
})

export const setFiltersHas = payload => ({
  type: 'INFO_FILTERS_HAS',
  payload
})

export const setFiltersActive = payload => ({
  type: 'INFO_FILTERS_ACTIVE',
  payload
})

export const cleanFilters = () => ({
  type: 'INFO_FILTERS_CLEAN',
})

export const setFiltered = payload => ({
  type: 'INFO_FILTERED',
  payload
})

export const filteredItens = e => {
  return dispatch => {
    localStorage.setItem('filtered', JSON.stringify(e))
    dispatch(setFiltered(e))
  }
}




export const titlePage = ({pathname, menuItens}) => {
  return dispatch => {
    menuItens?.map(d=> {
      if (d.sub) {
        const activeItem = d.sub.filter(e=> pathname === e.go)
        if (activeItem.length) {
          dispatch(setTitlePage(activeItem[0].label))
        }
      } else {
        if (pathname.includes(d.go)) {
          dispatch(setTitlePage(d.label))
        }
      }
    })
  }
}

export const setProjeto = (e, url='') => {
  console.error(e, url, 'setProjeto');
  const pathname = history.location.pathname.split('/')
  console.error(pathname, 'setProjetopathname');
  if (pathname[2]) {
    pathname[2] = e.id
    let novaUrl = pathname.join('/');
    console.error(novaUrl, 'setProjetopathname novaUrl');

    if (e.temMenuProduto) {
      const urlPode = {
        resumogrupos: 'resumogruposprodutos',
        csatestabelecimentos: 'csatprodutoscsatcliente',
        npsestabelecimentos: 'npsprodutosnpscliente',
        csatestabelecimentosconcorrentes: 'csatprodutosconcorrentes',
        npsestabelecimentosconcorrentes: 'npsprodutosconcorrentes',
        indicadores: 'indicadoresprodutos',
        analiseswot: 'analiseswotprodutos',
      }
      if (urlPode[pathname[3]]) {
        pathname[3] = urlPode[pathname[3]]
        novaUrl = pathname.join('/');
      }
    }
    if (e.temMenuEstabelecimento) {
      const urlPode = {
        resumogruposprodutos: 'resumogrupos',
        csatprodutoscsatcliente: 'csatestabelecimentos',
        npsprodutosnpscliente: 'npsestabelecimentos',
        csatprodutosconcorrentes: 'csatestabelecimentosconcorrentes',
        npsprodutosconcorrentes: 'npsestabelecimentosconcorrentes',
        indicadoresprodutos: 'indicadores',
        analiseswotprodutos: 'analiseswot',
      }
      if (urlPode[pathname[3]]) {
        pathname[3] = urlPode[pathname[3]]
        novaUrl = pathname.join('/');
      }
    }
    history.push(novaUrl)
  } 
  if(url){
    history.push(url)
  }

  const cicloReturn = e.ciclos.map(c=> cicloIntervalo(c))

  return dispatch => dispatch([
    detlaharProjeto(e),
    setListarCiclos(cicloReturn),
    setInfoCiclo(cicloReturn[0]),
    
        setListarCategorias(e.categorias),
        setInfoCategoria(e.categorias[0]),
        
  ])
}

export const detlaharProjeto = e => {
  console.error(e, 'DetlaharProjeto');

  return dispatch => { 
    if (e.temMenuEmpresa) {
      dispatch(listarMeusProjetosEmpresas({idProjeto: e.id}))
    }
    if (e.temMenuEstabelecimento) {
      dispatch(listarMeusProjetosEstabelecimentos({idProjeto: e.id}))
      dispatch(listarGruposEstabelecimentos(e.id))
    }
    if (e.temMenuProduto) {
      dispatch(listarMeusProjetosProdutos({idProjeto: e.id}))
      dispatch(listarGruposProdutos(e.id))
    }
  }
}


// export const setProjeto = e => {
 
//   const cicloReturn = e.ciclos.map(c=> cicloIntervalo(c))
//   const respGrupos = e.grupos.map((g, i)=> ({id: i, name: g.nomeGrupo?g.nomeGrupo:'grupo', ...g}))
  
//   localStorage.setItem('projetoINSV', JSON.stringify(e))
//   return dispatch => dispatch([
//     setInfoProjeto(e),
//     setListarGrupos(respGrupos),
//     setInfoGrupo(respGrupos[0]),
//     setListarCiclos(cicloReturn),
//     setInfoCiclo(cicloReturn[0]),

//     setListarEmpresas(e.empresas),
//     setInfoEmpresa(e.empresas[0]),
//     setFilters({name: 'empresa', value: {}}),
//     setFilters({name: 'categoria', value: {}}),

//     // setInfoCliente(e.cliente),
//     // setListarGrupos(e.grupos),
//     // setInfoGrupo(e.grupos[0]),
//     // setListarEmpresas(e.empresas),
//     // setInfoEmpresa(e.empresas[0]),
//     // setListarCategorias(e.categorias),
//     // setInfoCategoria(e.categorias[0]),
//   ])
// }

export const listarProjetos = e => {
  return dispatch => {
    // api.get(`/Projetos/${e}`)
    console.error('PASSO1');
    //apiOld.get(`/Usuario/${e}`)
    apiV3.get(`/Usuario/V3/${e}`)
    .then(result => {
      const projetoinsv = JSON.parse(localStorage.getItem('projetoINSV'))
      //console.error('PASSO2');
      //const projetoUsefy = projetoinsv?.id ? projetoinsv : result.data.projetos[0]
      const projetoUsefy = result.data.informacoesProjetos[0];
      //const projetoUsefy = result.data.projetos;
      //console.error('PASSO3');
      //console.error(projetoUsefy, 'PROJETO-USEFY:');
      const cicloReturn = projetoUsefy.ciclos.map(c=> cicloIntervalo(c))
      //console.error('PASSO4');
      const respGrupos = projetoUsefy.grupos.map((g, i)=> ({id: i, name: g.nomeGrupo?g.nomeGrupo:'grupo', ...g}))
      //console.error('PASSO5');
      //console.error('PARAMETRO E' + e);
      //console.error('GRUPOS LIDOS DE ZERO' + respGrupos[0]);
      //console.error(respGrupos, 'RESPGRUPOS LIDO:');
      //console.error(grupos, 'GRUPOS LIDO:');
    console.warn(projetoUsefy, 'projetoUsefyprojetoUsefyprojetoUsefyprojetoUsefyprojetoUsefy');

      dispatch([
        setListarProjetos(result.data.informacoesProjetos),
        setInfoProjeto(projetoUsefy),
        setListarEmpresas(projetoUsefy.empresas),
        setInfoEmpresa(projetoUsefy.empresas[0]),
        setListarGrupos(respGrupos),
        setInfoGrupo(respGrupos[0]),
        setListarCiclos(cicloReturn),
        setInfoCiclo(cicloReturn[0]),
        
        setListarCategorias(result.data.categorias),

        setFiltersHas({name: 'ciclo', value: cicloReturn[cicloReturn.length - 1]})
      ])
      
      console.error('PASSO6');
    })
    .catch(err => {
        console.error(err, 'listarProjetos');
        // dispatch(logOut())
    });
  }
}

export const listarGrupos = e => {
  return dispatch => {
    console.error('PASSO1A');
    api.get(`/Grupos${''}`)
    .then(result => {
      console.error('PASSO2A');
      const respGrupos = result.data.map((g, i)=> ({id: i, name: g.nomeGrupo?g.nomeGrupo:'grupo', ...g}))
      console.error('PASSO31A');
      dispatch(setListarGrupos(respGrupos))
      console.error('PASSO4A');
    })
    .catch(err => {
        console.error(err, 'listarProjetos');
    });
  }
}

const setLogIn = e => ({
  type: 'LOG_IN',
  payload: e
})

export const logIn = e => {
  return dispatch => {
    dispatch(loading())
    const params = {
      email: e.email,
      password: e.senha
    }

    apiAuth.post(`/Auth/Login`, params)
    .then(response => {
      console.error(response.data, 'response.dataresponse.dataresponse.dataresponse.data');
      if (response.data.isSuccess) {
        localStorage.setItem('tolkenINSV', `Bearer ${response.data.message}`)
        // dispatch([logUser(e.email)])
        dispatch([logUserNew(e)])
      } else {
        dispatch([setLogIn(false), loaded()])
      }
    })
    .catch(error => {
      dispatch([
        AddAlert('danger', 'E-mail e/ou senha invalido(s)'),
        setLogIn(false), 
        loaded()
      ])
    })
  }
}

export const logUser = e => {
  return dispatch => {
    //apiOld.get(`/Usuario/${e}`)
    apiV3.get(`/Usuario/V3/${e}`)
    //apiV3.get(`/Usuario/${e}`)
    .then(response => {
      dispatch(logUserNew(response.data))
    })
    .catch(error => {
      console.error(error, 'logUser error');
      dispatch(loaded())
    })
  }
}

export const logUserNew = e => {
  return dispatch => {
    api.get(`/Usuario/InformaMeusDadosCadastro`)
    .then(response => {
      // const infoUser = {...e, ...response.data}
      const infoUser = response.data
      //console.error(response.data,'INFORMAMEUSDADOSCADASTRO')
      dispatch([
        setLogIn(true),
        // listarMeusProjetos(e.projetos),
        // setListarProjetos(e.projetos),
        loaded(),
      ])
      localStorage.setItem('projetosINSV', JSON.stringify(e.projetos))
      dispatch(logedPermissoes(infoUser))
      dispatch(informacoesLogin())
      if (!(e.permissoes && Object.keys(e.permissoes).length)) {
        history.push('/meusprojetos')
      }
    })
    .catch(error => {
      console.error(error, 'logUser error');
      dispatch(loaded())
    })
  }
}

export const loged = e => {
  const user = localStorage.getItem('userINSV')
  const usuario = JSON.parse(user)
  // const projects = localStorage.getItem('projetosINSV')
  // const projetos = JSON.parse(projects)
  return dispatch => {
    if (user) {
      dispatch([
        setLogIn(true),
        setCarregarUsuarioLogado(usuario),
        informacoesLogin(),
        // setListarProjetos(projetos),
      ])
    } else {
      dispatch(logOut())
    }
  }
}


export const informacoesLogin = e => {
  return dispatch => {
    api.get(`/Usuario/InformacoesLogin`)
    .then(response => {
      dispatch([
        getSistema({name: 'login', value: response.data}),
        getSistema({name: 'idiomaIdValue', value: response.data.idioma.id}),
        getSistema({name: 'idioma', value: idiomaString(response.data?.idioma?.nome)}),
        getNotificacoes(response.data.notificacoes)
      ])
    })
    .catch(error => {
      console.error(error, 'logUser error');
    })
  }
}

export const logedPermissoes = e => {
  return dispatch => {
    api.get(`/Usuario/PermissoesUsuarios`)
    .then(response => {
      const infoUser = {...e, permissoes: response.data.reduce((a, v) => ({ ...a, [v.tipo]: v.status}), {})}
      dispatch([
        setCarregarUsuarioLogado(infoUser),
        loaded(),
      ])
      localStorage.setItem('userINSV', JSON.stringify(infoUser))
      localStorage.setItem('userPermissionsINSV', JSON.stringify(infoUser.permissoes))
    })
    .catch(error => {
      console.error(error, 'logUser error');
    })
  }
}

export const logOut = e => {
  localStorage.clear()
  return dispatch => {
    dispatch([
      setLogIn(false), 
      modalOpen(''),  
      setCarregarUsuarioLogado({})
    ])
    history.push('/')
  }
}

export const recuperarSenha = e => {
  return dispatch => {
    api
      .put(`/usuario/recuperarSenha/${e.email}`)
      .then(response => {
        dispatch([
          AddAlert('success', 'Senha enviada para o seu e-mail'),
          history.goBack()
        ])
      })
      .catch(error => {
        dispatch([setLogIn(false), AddAlert('error', nls.mensagem[error.request.response])])
      })
  }
}



export const setListasData = payload => ({
  type: 'SET_LISTAS_DATA',
  payload
})

export const setShowNotificacoes = payload => ({
  type: 'SET_SHOW_NOTIFICACOES',
  payload
})

export const getNotificacoes = payload => ({
  type: 'GET_NOTIFICACOES',
  payload
})

export const deleteNotificacoes = payload => ({
  type: 'DELETE_NOTIFICACOES',
  payload
})

export const deleteNotificacao = payload => ({
  type: 'DELETE_NOTIFICACAO',
  payload
})

export const listarNotificacoes = e => {
  const notificacoes = [
    {
      id:'1',
      titulo: 'Erro no registro da Empresa 1',
      conteudo: 'Nosso sistema detectou um erro na validação do link para o registro do Facebook.',
      data: new Date().toISOString()
    },
    {
      id:'2',
      titulo: 'Erro no registro da Empresa 3',
      conteudo: 'Nosso sistema detectou um erro na validação do link para o registro do Facebook.',
      data: new Date().toISOString()
    }
  ]
  return dispatch => {
    dispatch(getNotificacoes(notificacoes))
  }
}

export const listarEstados = e => {
  const listaEstados = listasJson.estados.find(estado=> estado.id === e.id)
  return dispatch => {
    dispatch(setListasData({name: 'estados', value: listaEstados.lista}))
  }
}

export const listarCidades = e => {
  const listaCidades = listasJson.cidades.find(cidade=> cidade.id === e.id)
  return dispatch => {
    dispatch(setListasData({name: 'cidades', value: listaCidades.lista}))
  }
}

export const getIp = e => {
  return dispatch => {
    apiIp.get(`/getip/Ip`)
    .then(response => {
      dispatch([
        getSistema(response.data),
        listarPaises(response.data),
        listarSegmentos(response.data),
        getSistema({name: 'idioma', value: idiomaString(response.data.idioma)}),
        getSistema({name: 'moeda', value: idiomaString(response.data.moeda)}),
        // getSistema({name: 'pais', value: paisId})
        // listarIdiomas(response.data)
      ]) 
      
    })
    .catch(error => {
      console.error(error, 'error getIp');
      const ip = {
        "countrycode": "BR",
        "countryname": "Brazil",
        "region": "Federal District",
        "city": "Brasília",
        "idiomaId": 1,
        "errmsg": null,
        "idioma": "en"
      }
      dispatch(listarPaises(ip))
      dispatch(listarSegmentos(ip))
      dispatch(
        getSistema({name: 'idioma', value: idiomaString(ip.idioma)}))
    })
  }
}

export const listarPaises = e => {
  // const params = paramsApi(e)
  return dispatch => {
    api.get(`/Pais/ListaPaises`, {headers:{SenhaAuthorization: `${process.env.REACT_APP_INSV_SENHA_API}`}})
    .then(response => {
      let paisesIdiomaResult = {}
      response.data.map(s=> {
        paisesIdiomaResult = {...paisesIdiomaResult, [s.nome]: s.paises}
      })
      const paisesResult = response.data.find(s=> s.nome.toUpperCase() === e.idioma.toUpperCase())
      dispatch([
        setListasData({name: 'paises', value: paisesResult.paises}),
        setListasData({name: 'paisesIdiomas', value: paisesIdiomaResult}),
      ])
      console.error(paisesResult, paisesIdiomaResult, e, 'paisesResultpaisesResultpaisesResultpaisesResult');
      if (e.paisId) {
        const paisSelected = paisesResult.paises.find(p=> p.idPais === e.paisId)
        dispatch([
          getSistema({name: 'pais', value: paisSelected}),
          setProjetosFiltro({name: 'paises', value: paisSelected}),
        ])
      }
    })
    .catch(error => {
      console.error(error, 'error listarPaises');
      dispatch(setListasData({name: 'paises', value: listasJson.paises}))
    })
  }
}

export const listarSegmentos = e => {
  return dispatch => {
    api.get(`/Idioma/ListaSegmentos`, {headers:{SenhaAuthorization: `${process.env.REACT_APP_INSV_SENHA_API}`}})
    .then(response => {
      let segmentoIdiomaResult = {}
      response.data.map(s=> {
        segmentoIdiomaResult = {...segmentoIdiomaResult, [s.idioma.idioma]: s.segmentos}
      })
      const segmentoResult = response.data.find(s=> s.idioma.idioma === e.idioma)
      dispatch([
        setListasData({name: 'segmentos', value: segmentoResult.segmentos}),
        setListasData({name: 'segmentosIdiomas', value: segmentoIdiomaResult}),
      ])     
      console.error(segmentoResult, segmentoIdiomaResult, e, 'segmentoIdiomaResultsegmentoIdiomaResultsegmentoIdiomaResultsegmentoIdiomaResultsegmentoIdiomaResult');
      if (e.paisId) {
        const segmentoSelected = segmentoResult.segmentos[0]
        dispatch([
          setProjetosFiltro({name: 'segmentos', value: segmentoSelected}),
        ])
      }
    })
    .catch(error => {
      console.error(error, 'error listarSegmentos');
    })
  }
}


export const listarIdiomas = e => {
  return dispatch => {
    api.get(`/Idioma/ListaIdiomas`)
    .then(response => {
        const idiomaSplit = e.idioma.split('-')
        const idioma = idiomaSplit.length>1?e.idioma.replace(idiomaSplit[1], idiomaSplit[1].toUpperCase()): e.idioma
      dispatch([
        getSistema({name: 'idioma', value: idioma}),
        getSistema({name: 'idiomas', value: response.data}),
      ])
    })
    .catch(error => {
      console.error(error, 'error listarIdiomas');
    })
  }
}



export const informeNaoMostrar = e => {
  return dispatch => {
    api.post(`/Usuario/RegistraNaoReceberBoasVindas`)
    .then(response => {
      console.warn(response, 'response informeNaoMostrar');
    })
    .catch(error => {
      console.error(error, 'error informeNaoMostrar');
    })
  }
}

export const userVisualizouNotificacoes = e => {
  return dispatch => {
    api.put(`/Usuario/RegistraVisualizouNotificacoes`)
    .then(response => {
      console.warn(response, 'response userVisualizouNotificacoes');
    })
    .catch(error => {
      console.error(error, 'error userVisualizouNotificacoes');
    })
  }
}

export const apagarNotificacao = e => {
  console.warn(e, 'apagarNotificacao');
  let params = []
  e.map(f=> {
    params = [...params, (f.idNotificao)]
  })
  return dispatch => {
    api.put(`/Usuario/RegistraApagarNotificacao`, params)
    .then(response => {
      if (params.length === 1) {
        dispatch(deleteNotificacao(params[0]))
      } else {
        dispatch(deleteNotificacoes())
      }
    })
    .catch(error => {
      console.error(error, 'error apagarNotificacao');
    })
  }
}



export const verificaConsumoConta = e => {
  return dispatch => {
    api.get(`/Usuario/VerificaConsumoConta`)
    .then(response => {
      dispatch(getSistema({name: 'consumoConta', value: response.data}))
    })
    .catch(error => {
      console.error(error, 'error verificaConsumoConta');
    })
  }
}



export const verificaCreditoConta = e => {
  return dispatch => {
    api.get(`/Usuario/VerificaCreditosConta`)
    .then(response => {
      dispatch(getSistema({name: 'creditoConta', value: response.data}))
    })
    .catch(error => {
      console.error(error, 'error verificaCreditoConta');
    })
  }
}

export const PosCheckOutXD = e => {
  return dispatch => {
    apiBase.get(`/v4/api/PosCheckOutXD?session_id=${e}`)
    .then(response => {
      const userInsvString = localStorage.userINSV
      const userInsv = JSON.parse(userInsvString)
      console.error(response, userInsv, 'PosCheckOutXD');
      dispatch([
        verificaCreditoConta(),
        logedPermissoes(userInsv),
        listarMeusProjetos(),
      ])
    })
    .catch(error => {
      console.error(error, 'error verificaCreditoConta');
    })
  }
}

export const PortalClienteXD = e => {
  return dispatch => {
    apiBase.get(`/v4/api/PortalClienteXD?urlVoltaDoPortal=${e}`)
    .then(({data}) => {
      console.log(data, 'PortalClienteXD');
      window.location.href = data.urlPortal
    })
    .catch(error => {
      console.error(error, 'error PortalClienteXD');
    })
  }
}