import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IcoCicle, RadioButton, Select } from '../../../components'
import { putFiltersProvisorio } from '../redux/basededadosActions'

export default () => {
  const dispatch = useDispatch()
  const { ciclos } = useSelector(state=> state.layoutState)
  const { filtersProvisorio: {ciclo, compararCiclos} } = useSelector(state=> state.basededadosState)

  const handleFormFilter = (e) => {
    dispatch(putFiltersProvisorio(e))
  }

  const compararCiclosList = [
   {
      id: 'desativar',
      name: 'Desativar',
   },
   {
      id: 'ativar',
      name: 'Ativar',
   }
  ]
  console.log(ciclos, 'ciclosciclosciclosciclosciclosciclosciclosciclos');
  return (
   <div className='box-basededados-search basededados-search-ciclo'>
      <Select
         name='ciclo'
         label='Ciclo'
         options={ciclos}
         selected={ciclo}
         action={(e)=> handleFormFilter(e)}
         selectedItem={false}
         labelCustom={(e)=> <><IcoCicle /> {e.mesAno}</> }
         optionLabel='mesAno'
         optionValue='anoMes'
      />
      {/* <RadioButton
         name='compararCiclos'
         label='Comparar ciclos'
         options={compararCiclosList}
         checked={compararCiclos}
         action={(e)=> handleFormFilter(e)}
      /> */}
   </div>
  )
}
